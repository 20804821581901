import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { currencyFormat } from '@/vue-app/utils/currency';

// Infrastructure
import CustomerContractedInvestmentProductsVueStateManager
  from '@/modules/flagship/customer-contracted-investment-products/infraestructure/state/customer-contracted-investment-products-vue-state-manager';

// Application
import GetRetirementInvestorGoalQuery from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class RetirementCardViewModel {
  @Inject(TYPES.CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_STATE_MANAGER)
  // eslint-disable-next-line max-len
  private readonly customer_contracted_products_state!: CustomerContractedInvestmentProductsVueStateManager;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_goal_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.retirement-fund-card';

  private readonly MONTHS_IN_ONE_YEAR = 12;

  is_loading = false;

  is_active = false;

  associated_contract = false;

  associated_product_id = '';

  retirement_age = 0;

  retirement_range_adjusted = 0;

  accumulated_amount = '';

  final_investment_date = '';

  show_flagship_link_wealth_wizard = false;

  modal_prepare_documents_wealth = {
    is_open: false,
  };

  bg_image = {
    backgroundImage: '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  investor_goal_state = this.create_investor_goal_state_manager.state;

  get retirement_range_in_years() {
    return this.retirement_range_adjusted / this.MONTHS_IN_ONE_YEAR;
  }

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }
    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  }

  initialize = async () => {
    this.is_loading = true;
    await this.loadRetirementInvestorGoalInformation();
    await this.loadCustomerContractedProductsInformation();
    this.is_loading = false;
  }

  showFlagshipLinkWealthWizard = () => {
    this.show_flagship_link_wealth_wizard = true;
  }

  showModalPrepareDocumentsWealth = () => {
    this.modal_prepare_documents_wealth.is_open = true;
  }

  loadRetirementInvestorGoalInformation = async () => {
    try {
      const {
        investor_goal,
        retirement_age_adjusted,
        retirement_range_adjusted,
      } = await this.get_retirement_investor_goal_query.execute();
      this.is_active = investor_goal.is_active!;
      this.retirement_age = retirement_age_adjusted;
      this.retirement_range_adjusted = retirement_range_adjusted;
      this.associated_product_id = investor_goal.associated_product_id || '';
      this.accumulated_amount = `${currencyFormat(investor_goal.accumulated_amount!)} MXN`;
      this.final_investment_date = investor_goal.final_investment_date || '';
      if (investor_goal.final_investment_date) {
        this.investor_goal_state.retirement_fund.already_created = true;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_retirement_investor_goal_information'));
      }
    }
  }

  loadCustomerContractedProductsInformation = () => {
    try {
      const { has_sowos_wealth_contracted } = this.customer_contracted_products_state.state.item;
      this.associated_contract = has_sowos_wealth_contracted;
      if (!this.associated_contract) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
        const bgImage = require('@/assets/images/goals-dashboard/goal_wealth.png');
        this.bg_image.backgroundImage = `url(${bgImage})`;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_customer_contracted_products'));
    }
  }
}
